(function ($) {

    // DOM ready
    $(function () {
        $('.btn--burger').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
        });

        $('.gallery-carousel .close-btn').on('click', function (e) {
            e.preventDefault();
            $(this).parent().fadeOut(300);
        });

        $('.gallery-grid__item a').on('click', function (e) {
            e.preventDefault();
            var id = $(this).attr("href");
            var index = $(this).parent().index();
            $(id + ' *[data-js="gallery-slider"]').slick('slickGoTo', index);
            $(id + '.gallery-carousel').fadeIn(300);
            $(id + ' *[data-js="gallery-slider"]').slick('resize');
        });

        $('.scroll-top-btn').on('click', function (e) {
            e.preventDefault();
            $('body, html').animate({
                scrollTop: 0
            }, 1000);
        });

        $(document).ready(function () {
            $('*[data-js="cover-img"]').each(function () {
                $(this).addClass('cover-init');
                $(this).css({
                    'background-image': 'url(' + $(this).find('img').attr('src') + ')'
                })
            });

            $('*[data-js="main-carousel"]').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 6000,
                arrows: false,
                dots: true
            });

            $('*[data-js="gallery-slider"]').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                arrows: true,
                dots: true,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 576,
                        settings: {
                            arrows: false
                        }
                    }
                ]
            });
        });
    });

})(jQuery);